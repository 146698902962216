import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

const InfiniteImageCarousel = ({ images }) => {
    return (
        <div className="infinite-carrousel">
            <div className="infinite-carrousel__track">
                {images.map((item, index) => (
                    <div className="infinite-carrousel__track__logo" key={index}>
                        <GatsbyImage alt={item.alt} image={getImage(item.image.localFile)} />
                    </div>
                ))}
                {images.map((item, index) => (
                    <div className="infinite-carrousel__track__logo" key={index}>
                        <GatsbyImage alt={item.alt} image={getImage(item.image.localFile)} />
                    </div>
                ))}
                {images.map((item, index) => (
                    <div className="infinite-carrousel__track__logo" key={index}>
                        <GatsbyImage alt={item.alt} image={getImage(item.image.localFile)} />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default InfiniteImageCarousel